.podBtn{
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    background-color: #f7a01e;
    color: white;
    border: none;
  }


  .ImgBoxGuets2{


    height: auto;
    width: 100%
  }

